import success_img from "./success.svg";
import failed_img from "./failed.svg";
import afriex from "./afriex.svg";
import "./App.css";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import { useEffect, useState } from "react";

function Home() {
  const [callbackStatus, setCallbackStatus] = useState();
  const { search } = useLocation();
  const { status } = queryString.parse(search);
  useEffect(() => {
    setCallbackStatus(status);
  });

  return (
    <div className="App">
      <header className="App-header">

            <p className="top-text">
              {callbackStatus !== "failed"
                ? "Transaction successful"
                : " Transaction failed"}
            </p>
            {callbackStatus !== "failed" ? (
              <img src={success_img} className="App-logo" alt="logo" />
            ) : (
              <img src={failed_img} className="App-logo" alt="logo" />
            )}

            <p>{callbackStatus !== "failed"
                ? "Start sending money now!"
                : "Please try again"} </p>
   

      </header>
    </div>
  );
}

export default Home;
