import logo from "./mono.svg";
import "./App.css";
// import { useLocation } from "react-router-dom"
import queryString from "query-string";
// import { useSearchParams } from 'react-router-dom';
import { BrowserRouter, Link, Routes, Route } from "react-router-dom";
import Home from "./home";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
